<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="编号">
              <a-input v-model="queryParam.receipt_id" placeholder="请输入全部领取编号" allow-clear @keyup.enter.native="$refs.table.refresh(true)"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" @keyup.enter.native="$refs.table.refresh(true)">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.InspurReceiptStatus">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" icon="plus" @click="handleAdd" :loading="btnloading">新建</a-button>
      <div>
        <span style="float:right; font-size:25px">
          剩余数量:{{ count }}
        </span>
      </div>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleUpdate(record)" v-if="record.status < 3">更新状态</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'

import {
  exchange_rate_create,
  exchange_rate_delete
} from '@/api/exchange_rate'
import {
  inspur_get_receipt_info_list,
  inspur_qid_apply_create,
  inspur_qid_apply_list,
  inspur_qid_list
} from '@/api/inspur_qid'

export default {
  name: 'InspurQid',
  components: {
    STable,
    CreateForm
  },
  data () {
    return {
      count: 0,
      btnloading: false,
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '编号',
          dataIndex: 'inspur_code'
        },
        {
          title: '领取单号',
          dataIndex: 'receipt_id',
          customRender: (text) => text || '-'
        },
        {
          title: '更新时间',
          customRender: (text) => text || '-',
          dataIndex: 'last_update'
        },
        {
          title: '状态',
          customRender: (text) => this.$Dictionaries.InspurReceiptStatus[text],
          dataIndex: 'status'
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('电费参数', parameter)
        return inspur_qid_apply_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            console.log(data, '我是数据列表@')
            this.getinspur_qid_apply_list()
            return data
          })
      }
    }
  },
  created () {
    console.log(this.$route, '我是route！！！')
    console.log(this.$Dictionaries)
    this.getinspur_qid_apply_list()
  },
  methods: {
    handleAdd () {
      /* this.mdl = null
      this.visible = true */
      this.btnloading = true
      inspur_qid_apply_create().then((res) => {
        console.log(res)
        this.btnloading = false
        this.$refs.table.refresh()
      }).catch((err) => {
        console.log(err)
        this.btnloading = false
      })
    },
    getinspur_qid_apply_list () {
      inspur_qid_list().then((res) => {
        console.log(res)
        this.count = res.count
      })
    },
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handlePayCancel () {
      this.payvisible = false
      const form = this.$refs.createPayModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      /*
      this.current_bill_id = record.id
      this.payvisible = true */
      inspur_get_receipt_info_list({ data_id: record.receipt_id }).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    handleDelet (record) {
      exchange_rate_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          exchange_rate_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            })
            .catch((err) => {
              console.log(err)
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
