import request from '@/utils/request'

const inspur_apply_qidApi = {
  inspur_get_receipt_info_list: '/inspur/get_receipt_info/',
  inspur_qid_list: '/inspur/qid/',
  inspur_qid_apply_list: '/inspur/qid/apply/',
  inspur_qid_apply_create: '/inspur/qid/apply/'
}

/**
 * 列表
 */
export function inspur_get_receipt_info_list (parameter) {
  return request({
    url: inspur_apply_qidApi.inspur_get_receipt_info_list,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 列表
 */
export function inspur_qid_list (parameter) {
  return request({
    url: inspur_apply_qidApi.inspur_qid_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function inspur_qid_apply_list (parameter) {
  return request({
    url: inspur_apply_qidApi.inspur_qid_apply_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function inspur_qid_apply_create (parameter) {
  return request({
    url: inspur_apply_qidApi.inspur_qid_apply_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
